import TextInput from "./TextInput";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/config"; // Увери се, че пътят е правилен

const Block = ({ block, index, updateBlock, removeBlock }) => {
  const handleImageUpload = async (file) => {
    if (!file) return;

    const storageRef = ref(storage, `landing-images/blocks/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);

    // Актуализираме блок с новото изображение
    updateBlock(index, { ...block, imageUrl: url });
  };

  return (
    <div className="p-4 mt-4 rounded-sm block-item bg-gray-50">
      <TextInput
        label="Заглавие"
        value={block.title}
        onChange={(e) =>
          updateBlock(index, { ...block, title: e.target.value })
        }
        placeholder="Заглавие"
      />
      <textarea
        value={block.text}
        onChange={(e) => updateBlock(index, { ...block, text: e.target.value })}
        placeholder="Текст"
        rows={6}
        className="w-full mt-4 border-gray-300 rounded-lg placeholder:text-slate-400"
      />
      {/* Полета за бутони */}
      <TextInput
        label="Име на бутон 1"
        value={block.button1Text}
        onChange={(e) =>
          updateBlock(index, { ...block, button1Text: e.target.value })
        }
        placeholder="Име на бутон 1"
        helpText="Този бутон е тъмносин и е винаги на първо място. Използвайте го за връзка към други страници в сайта или към външни източници."
      />
      <TextInput
        label="URL на бутон 1"
        value={block.button1Url}
        onChange={(e) =>
          updateBlock(index, { ...block, button1Url: e.target.value })
        }
        placeholder="URL на бутона"
      />
      <TextInput
        label="Име на бутон 2"
        value={block.button2Text}
        onChange={(e) =>
          updateBlock(index, { ...block, button2Text: e.target.value })
        }
        placeholder="Име на бутон 2"
        helpText="Този бутон е винаги втори, без фон и с тъмносиня рамка. Използвайте го за връзка към други страници в сайта или външни източници."
      />
      <TextInput
        label="URL на бутон 2"
        value={block.button2Url}
        onChange={(e) =>
          updateBlock(index, { ...block, button2Url: e.target.value })
        }
        placeholder="URL на бутона"
      />
      <TextInput
        label="Заглавие на бутон за скрол до формата"
        value={block.buttonScrollTitle}
        onChange={(e) =>
          updateBlock(index, { ...block, buttonScrollTitle: e.target.value })
        }
        placeholder="Заяви тестдрайв или друго подобно"
        helpText="Този бутон е винаги последен, без фон и с тъмносиня рамка. Използвайте го, ако искате потребителите да скролват до формата за контакт."
      />

      {/* Качване и визуализация на изображение */}
      <div className="mt-4">
        <label className="block">Изображение</label>
        <p className="text-sm text-gray-400">
          Препоръчва се това изображение да е приблизително 700px по ширина. То
          ще се мащабира автоматично, ако е по-голямо, но това може да влоши
          бързодейтвието на сайта. Височината му се определя автоматично,
          запазвайки пропорциите му.
        </p>
        {block.imageUrl && (
          <img
            src={block.imageUrl}
            alt={block.title || "Block Image"} // Използваме заглавието на акцента за alt
            className="w-auto h-32 mt-2 border"
          />
        )}
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageUpload(e.target.files[0])}
          className="mt-2"
        />
      </div>

      <button
        onClick={() => removeBlock(index)}
        className="px-4 py-2 mt-4 bg-white border rounded text-fordRed hover:text-white border-fordRed hover:bg-fordRed"
      >
        Изтрий блок
      </button>
    </div>
  );
};

export default Block;
