import { Link, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

function AdminLayout() {
  const { user, authIsReady } = useAuthContext();

  // Първо проверяваме дали аутентификацията е готова
  if (!authIsReady) {
    return <div>Зареждане...</div>; // или можеш да добавиш спинер
  }
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return (
    <div className="container p-16 text-center">
      <h1 className="text-lg tracking-widest text-center uppercase font-fordMedium text-fordBlue">
        Административен панел
      </h1>
      <div className="flex gap-8 p-8 my-8 bg-gray-100">
        <Link
          to="/admin/upload"
          className="w-1/2 py-4 border rounded border-fordBlue text-fordBlue"
        >
          Нов списък с автомобили
        </Link>
        <Link
          to="/admin/stats"
          className="w-1/2 py-4 border rounded border-fordBlue text-fordBlue"
        >
          Статистика
        </Link>
        <Link
          to="/admin/landingpages"
          className="w-1/2 py-4 border rounded border-fordBlue text-fordBlue"
        >
          Лендинг страници
        </Link>
      </div>
      <Outlet />
    </div>
  );
}

export default AdminLayout;
