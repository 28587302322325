import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, doc, updateDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config"; // Актуализирай това спрямо твоята Firebase конфигурация
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import TextInput from "./TextInput"; // Импортираме новия компонент
import BlockManager from "./BlockManager"; // Импортираме компонента за блокове
import LogoLoader from "./LogoLoader"; // Импортираме твоето лого за зареждане
import Modal from "./Modal"; // Импортираме модала

const LandingPageForm = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Взима id на страницата за редакция
  const isEditMode = Boolean(id); // Проверява дали сме в режим на редакция
  const [pageData, setPageData] = useState({});

  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [desktopImage, setDesktopImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [buttonUrl, setButtonUrl] = useState("");
  const [selectedContactFields, setSelectedContactFields] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageField, setImageField] = useState(null); // Полето, което редактираме (desktopImage или mobileImage)
  const [imageList, setImageList] = useState([]); // Списък с изображения от Firebase
  const [newImage, setNewImage] = useState(null); // За новото изображение
  const storage = getStorage(); // Инициализация на Firebase Storage
  const [imageToDelete, setImageToDelete] = useState(null); // Съхранява изображението за изтриване
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Управлява модала за потвърждение на изтриване
  const allContactFields = [
    "имена",
    "имейл",
    "телефон",
    "съобщение",
    "предпочитано представителство",
  ];

  // Функция за актуализиране на блок
  const updateBlock = (index, updatedBlock) => {
    const newBlocks = [...blocks];
    newBlocks[index] = updatedBlock;
    setBlocks(newBlocks);
  };

  // Функция за премахване на блок
  const removeBlock = (index) => {
    const newBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(newBlocks);
  };

  // Функция за зареждане на данни от Firebase
  // Обвиваме fetchLandingPage в useCallback, за да предотвратим преизчисляване
  const fetchLandingPage = useCallback(async () => {
    try {
      console.log("Fetching data from Firebase for document ID:", id);
      const docRef = doc(db, "LandingPages", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Fetched data:", data);
        setPageData(data);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching landing page:", error);
    }
  }, [id]);

  useEffect(() => {
    if (isEditMode) {
      const fetchLandingPage = async () => {
        try {
          const docRef = doc(db, "LandingPages", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log("Fetched data:", data); // Вече имаш този лог

            // Задаване на стойности в state
            setTitle(data.title || "");
            setSlug(data.slug || "");
            setDescription(data.description || "");
            setDisclaimer(data.disclaimer || "");
            setDesktopImage(data.desktopImage || "");
            setMobileImage(data.mobileImage || "");
            setFormTitle(data.formTitle || "");
            setButtonUrl(data.buttonUrl || "");
            setSelectedContactFields(data.contactFields || []);
            setIsPublished(data.isPublished || false);
            setBlocks(data.blocks || []);

            console.log("State updated successfully!"); // Лог за проверка
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching landing page:", error);
        } finally {
          setLoading(false); // Спираме зареждането
        }
      };
      fetchLandingPage();
    } else {
      setLoading(false); // Ако не сме в режим на редакция, спираме зареждането
    }
  }, [id, isEditMode]);

  // Функция за създаване или обновяване на лендинг страницата
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked"); // Debug лог

    const data = {
      title,
      slug,
      description,
      disclaimer,
      desktopImage,
      mobileImage,
      formTitle,
      buttonUrl,
      contactFields: selectedContactFields,
      isPublished,
      blocks, // Включваме и блоковете при записването
      createdAt: new Date(), // Добавяме timestamp при създаване
    };

    try {
      if (isEditMode) {
        const docRef = doc(db, "LandingPages", id);
        await updateDoc(docRef, data);
      } else {
        // Използваме addDoc за създаване на нов документ с автоматично генерирано id
        await addDoc(collection(db, "LandingPages"), data);
      }
      navigate("/admin/landingpages");
    } catch (error) {
      console.error("Error saving landing page:", error);
    }
  };

  // Ако зареждаме данните, показваме LogoLoader
  if (loading) {
    return <LogoLoader />;
  }

  // Функция за извличане на изображения от Firebase Storage
  const fetchImages = async (folder) => {
    setImageList([]); // Изчистване на стария списък
    const imagesRef = ref(storage, folder);
    const imageRefs = await listAll(imagesRef);
    const urls = await Promise.all(
      imageRefs.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        console.log(`Извлечен URL: ${url}`); // Лог за проверка на URL
        return url;
      })
    );
    setImageList(urls);
  };

  // Функция за качване на ново изображение
  const handleUpload = async () => {
    if (!newImage) return;
    const folder =
      imageField === "desktopImage"
        ? "landing-images/desktop"
        : "landing-images/mobile"; // Определяме папката
    const imageRef = ref(storage, `${folder}/${newImage.name}`);
    await uploadBytes(imageRef, newImage);
    const url = await getDownloadURL(imageRef);
    setImageList((prev) => [...prev, url]); // Добавяме новото изображение към списъка
  };

  // Отваряне на модала и зареждане на изображенията
  const openImageModal = (field) => {
    setImageField(field);
    if (field === "desktopImage") {
      fetchImages("landing-images/desktop"); // Зареждаме изображенията от папката за десктоп
    } else {
      fetchImages("landing-images/mobile"); // Зареждаме изображенията от папката за мобилни устройства
    }
    setIsModalOpen(true);
  };

  // Избиране на изображение
  const selectImage = (url) => {
    if (imageField === "desktopImage") {
      setDesktopImage(url);
    } else {
      setMobileImage(url);
    }
    setIsModalOpen(false); // Затваряне на модала след избор
  };

  const confirmDeleteImage = (url) => {
    setImageToDelete(url); // Задаваме изображението за изтриване
    setIsModalOpen(false); // Затваряме текущия модал за избор на изображения
    setIsDeleteModalOpen(true); // Отваряме модала за потвърждение на изтриване
  };

  const handleDeleteImage = async () => {
    const imageRef = ref(storage, imageToDelete); // Препратка към изображението във Firebase Storage
    try {
      await deleteObject(imageRef); // Изтрива изображението от Firebase Storage
      setImageList((prev) => prev.filter((url) => url !== imageToDelete)); // Премахва изображението от списъка

      // Обнови съответното поле в зависимост от това кое изображение се изтрива
      const docRef = doc(db, "LandingPages", id); // Препратка към текущия документ

      if (imageToDelete === mobileImage) {
        setMobileImage(""); // Изчисти полето за mobileImage
        await updateDoc(docRef, { mobileImage: "" }); // Обнови Firestore документа
      } else if (imageToDelete === desktopImage) {
        setDesktopImage(""); // Изчисти полето за desktopImage
        await updateDoc(docRef, { desktopImage: "" }); // Обнови Firestore документа
      }

      setIsDeleteModalOpen(false); // Затваря модала за изтриване
      setImageToDelete(null); // Изчиства състоянието
    } catch (error) {
      console.error("Грешка при изтриване на изображението:", error);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="w-full mx-auto text-left"
        id="landingPageForm"
      >
        <details className="py-2 border-b border-gray-300">
          <summary className="uppercase text-fordBlue">
            Основна информация
          </summary>
          <div className="pl-4">
            <div className="grid grid-cols-2 gap-8">
              <div className="mt-8">
                <label className="block">
                  Основно изображение /за големи екрани/
                </label>
                <p className="text-sm text-gray-400">
                  Използвайте същото изображение, което е подготвено за карусела
                  на ford.bg. Препоръчва се размерът да е 1500х616 px и да няма
                  текст върху изображението.
                </p>
                {desktopImage && (
                  <div className="my-2">
                    <img
                      src={desktopImage}
                      alt="Desktop Preview"
                      className="object-cover w-auto h-32 border"
                    />
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => openImageModal("desktopImage")}
                  className="px-4 py-2 mt-2 border rounded text-fordBlue border-fordBlue"
                >
                  Избери или качи изображение
                </button>
              </div>

              <div className="mt-8">
                <label className="block">
                  Основно изображение /за мобилни устройства/
                </label>
                <p className="text-sm text-gray-400">
                  Използвайте същото изображение, което е подготвено за карусела
                  на ford.bg /780x960 px/. Ако не изберете изображение, ще се
                  използва това за големи екрани.
                </p>
                {mobileImage && (
                  <div className="my-2">
                    <img
                      src={mobileImage}
                      alt="Mobile Preview"
                      className="object-cover w-auto h-32 border"
                    />
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => openImageModal("mobileImage")}
                  className="px-4 py-2 mt-2 border rounded text-fordBlue border-fordBlue"
                >
                  Избери или качи изображение
                </button>
              </div>
            </div>
            <div className="mt-8">
              <TextInput
                label="Заглавие"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                helpText="Това заглавие ще се покаже в синьото поле под основната снимка на страницата"
              />
            </div>
            <div className="mt-8">
              <TextInput
                label="Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
                helpText="Това е част от URL-адреса на страницатa, например: https://sale.ford.bg/landingages/slug"
              />
            </div>
            <div className="mt-8">
              <label className="block">Описание</label>
              <textarea
                value={description}
                rows={6}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-4 py-2 mt-4 border border-gray-300 rounded"
              />
              <p className="mt-1 text-sm text-gray-500">
                Това е основния текст на страницата и се разполага
                непосредствено под заглавието /в тъмносиньото поле/
              </p>
            </div>
            <div className="my-8">
              <TextInput
                label="URL на бутона"
                value={buttonUrl}
                onChange={(e) => setButtonUrl(e.target.value)}
                helpText="Това е URL-адресът, към който потребителят ще бъде пренасочен, след като кликне на бутона ВИЖ НАЛИЧНИТЕ АВТОМОБИЛИ, който се намира в тъмносиньото поле със заглавието и описанието. Може да въведете адрес от вида https://sale.ford.bg/?model=Puma"
              />
            </div>
          </div>
        </details>
        <details className="py-2 border-b border-gray-300">
          <summary className="uppercase text-fordBlue">Акценти</summary>
          <BlockManager
            blocks={blocks}
            addBlock={() =>
              setBlocks([...blocks, { title: "", text: "", imageUrl: "" }])
            }
            updateBlock={updateBlock}
            removeBlock={removeBlock}
            className="pl-4 mt-8"
          />
        </details>
        <details className="py-2 border-b border-gray-300">
          <summary className="uppercase text-fordBlue">
            Форма за контакт
          </summary>
          <div className="pl-4 my-2">
            <TextInput
              label="Заглавие на формата за контакт"
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
              helpText="Това заглавие ще се покаже над формата за контакт. Ако не въведете заглавие, такова няма да се визуализира."
            />
            <label className="block mt-4">
              Изберете полетата, които искате да включва формата за контакт:
            </label>
            {allContactFields.map((field) => (
              <div key={field} className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedContactFields.includes(field)}
                  onChange={() =>
                    setSelectedContactFields(
                      selectedContactFields.includes(field)
                        ? selectedContactFields.filter((f) => f !== field)
                        : [...selectedContactFields, field]
                    )
                  }
                  className="mr-2"
                />
                <label>{field}</label>
              </div>
            ))}
          </div>
        </details>
        <details className="py-2 border-b border-gray-300">
          <summary className="uppercase text-fordBlue">Публикуване</summary>
          <div className="pl-4">
            <div className="mt-2">
              <TextInput
                label="Дисклеймър"
                value={disclaimer}
                onChange={(e) => setDisclaimer(e.target.value)}
                helpText="Този текст обяснява специфичните условия на промоцията и ще се покаже с дребен шрифт под формата за контакт. Ако не въведете текст, такъв няма да се визуализира."
              />
            </div>
            <div className="mt-8">
              <label className="block">Публикувана</label>
              <input
                type="checkbox"
                checked={isPublished}
                onChange={(e) => setIsPublished(e.target.checked)}
                className="mr-2"
              />
              <label>Да</label>
            </div>
          </div>
        </details>
        <button
          type="submit"
          className="px-4 py-2 mt-8 tracking-wider text-white uppercase rounded bg-fordBlue"
        >
          {isEditMode ? "Запази промените" : "Създай страницата"}
        </button>

        {/* Модал за избор и качване на изображение */}
        {isModalOpen && (
          <Modal handleClose={() => setIsModalOpen(false)}>
            <div>
              <h2 className="p-4 text-lg text-white bg-fordBlue">
                Избери изображение
              </h2>
              <div
                className={`grid gap-4 mt-4 ${
                  imageField === "desktopImage" ? "grid-cols-4" : "grid-cols-3"
                }`}
              >
                {imageList.map((url, index) => (
                  <div key={index} className="p-2 border border-gray-300">
                    <img
                      src={url}
                      alt="Изображение"
                      className={`${
                        imageField === "desktopImage"
                          ? "w-full h-[111px]"
                          : "w-48 h-full"
                      }`}
                      loading="lazy"
                    />
                    <div className="flex justify-between mt-2">
                      <button
                        className="px-2 py-1 text-white rounded bg-fordBlue bottom-2 left-2"
                        onClick={() => selectImage(url)} // Избира изображение за лендинг страницата
                      >
                        Избери
                      </button>
                      <button
                        className="px-2 py-1 text-white rounded bg-fordRed bottom-2 right-2"
                        onClick={() => confirmDeleteImage(url)} // Стартира процеса на изтриване
                      >
                        Изтрий
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="pl-4 mt-8 bg-gray-100">
                <label>Качи ново изображение:</label>
                <input
                  type="file"
                  onChange={(e) => setNewImage(e.target.files[0])}
                  className="mt-2 ml-2 rounded border-slate-300"
                />
                <button
                  type="button"
                  onClick={handleUpload}
                  className="px-4 py-2 mt-2 border rounded text-fordBlue border-fordBlue hover:text-white hover:bg-fordBlue"
                >
                  Запиши в библиотеката
                </button>
              </div>
            </div>
          </Modal>
        )}
      </form>
      {isDeleteModalOpen && (
        <Modal handleClose={() => setIsDeleteModalOpen(false)}>
          <p className="mb-4 text-lg text-center">
            Сигурни ли сте, че искате да изтриете това изображение? Това
            действие е необратимо.
          </p>
          <div className="flex justify-center gap-4">
            <button
              className="px-4 py-2 text-white bg-red-600 rounded"
              onClick={handleDeleteImage} // Изтрива изображението при потвърждение
            >
              Изтрий
            </button>
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded"
              onClick={() => setIsDeleteModalOpen(false)} // Затваря модала без действие
            >
              Отказ
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LandingPageForm;
